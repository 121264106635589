import React from 'react'

import Slideshow from './Slideshow'

const Header = ( { className, images, headerTitle, headerText, headerButtons, tint = 0, icon, blur = 0 } ) => {

     
    return (
        <section id="header" className={className}>
            <Slideshow images={images} enableTint tint={tint + 0.1} blur={blur} />

            <div className="inner">
                { icon && icon }
                { headerTitle ?
                    ( <>
                        <h1 className="header-title">
                            <span className="major" style={{ fontWeight: 600 }}>{headerTitle}</span>
                        </h1>
                        <hr/>
                    </>
                     ) : null
                    
                }



                { headerText ?
                    ( <p className="header-text" dangerouslySetInnerHTML={{ __html: headerText.replace( /\n/g, "<br />" ) }}/> ) : null

                    
                }
                {/* <ul className="actions"> 
                { headerButtons ?
                    headerButtons.map( ( { url, text } ) => (<li key={url}><AniLink fade to={url} className="button scrolly">{text}</AniLink></li>) ) :
                    (<li><AniLink fade to="#welcome" className="button scrolly">Discover</AniLink></li>)
                }
                </ul> */}
            </div>
        </section>
    )
}

// (<h1>Hi, I'm <strong>Photon</strong>, another fine<br />
//                     little freebie from <a href="http://html5up.net">HTML5 UP</a>.</h1>)

// (<p>Accumsan feugiat mi commodo erat lorem ipsum, sed magna<br />
//     lobortis feugiat sapien sed etiam volutpat accumsan.</p>)
export default Header
